import { jwtDecode } from "jwt-decode";
import Cookies from "universal-cookie";
import { client } from "./constants";
import { Mutex } from "async-mutex";

const cookies = new Cookies();

interface AuthCookie {
  accessToken: string;
  refreshToken: string;
}

interface AuthToken {
  exp: number;
  sub: string;
  admin?: boolean;
  impersonating_user_id?: string;
  viewing_as_user_id?: string;
}

export interface AuthInfo {
  userId: string;
  viewingUserId?: string;
  impersonating?: boolean;
  admin?: boolean;
  token: string;
}

export const AUTH_COOKIE = "stride_auth";
export const setCookieToken = (accessToken: string, refreshToken: string) => {
  const rt = jwtDecode<AuthToken>(refreshToken);

  cookies.set(
    AUTH_COOKIE,
    {
      accessToken,
      refreshToken,
    },
    { path: "/", sameSite: "strict", expires: new Date(rt.exp * 1000) },
  );
};

export const cookieExists = () => !!cookies.get(AUTH_COOKIE);

const mutex = new Mutex();
export const getToken = async (): Promise<AuthInfo> => {
  const release = await mutex.acquire();

  const auth = cookies.get(AUTH_COOKIE) as AuthCookie | undefined;
  if (!auth) {
    release();
    window.location.assign("/login");
    return { userId: "", token: "" };
  }

  const decoded = jwtDecode<AuthToken>(auth.accessToken);
  if (auth.accessToken && new Date(decoded.exp * 1000) < new Date()) {
    const newTokens = await client.exchangeToken({
      token: auth.refreshToken,
    });

    setCookieToken(
      newTokens.users.exchangeToken.accessToken,
      newTokens.users.exchangeToken.refreshToken,
    );

    release();
    return {
      token: newTokens.users.exchangeToken.accessToken,
      userId: decoded.sub,
    };
  }

  release();
  return {
    token: auth.accessToken,
    userId: decoded.impersonating_user_id ?? decoded.sub,
    viewingUserId: decoded.viewing_as_user_id,
    admin: decoded.admin,
    impersonating: !!decoded.impersonating_user_id,
  };
};
