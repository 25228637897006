"use client";

import { cookieExists } from "@/api/auth";
import { buttonVariants } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { ModeToggle } from "@/components/ui/theme-toggle";
import { cn } from "@/lib/utils";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { motion, useInView, useScroll } from "framer-motion";
import { Check } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { useReward } from "react-rewards";

import cal from "../../images/cal.png";
import calDark from "../../images/cal-dark.png";
import overview from "../../images/overview.png";
import overviewDark from "../../images/overview-dark.png";
import map from "../../images/map.png";
import mapDark from "../../images/map-dark.png";
import hrv from "../../images/hrv.png";
import hrvDark from "../../images/hrv-dark.png";
import activity from "../../images/activity.png";
import activityDark from "../../images/activity-dark.png";

import wahoo from "../../images/wahoo.png";
import garmin from "../../images/garmin.png";
import whoop from "../../images/whoop.png";
import strava from "../../images/strava.png";
import Head from "next/head";

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: ({ delay }: { delay: number }) => {
    const d = 1 + delay * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay: d, type: "spring", duration: 1.5, bounce: 0 },
        opacity: { delay: d, duration: 0.01 },
      },
    };
  },
};

export default function LandingPage() {
  const router = useRouter();
  const [smallNav, setSmallNav] = useState(false);
  const { scrollYProgress } = useScroll();

  useEffect(
    () =>
      scrollYProgress.onChange((latest) => {
        setSmallNav(latest > 0);
      }),
    [scrollYProgress],
  );

  useEffect(() => {
    if (cookieExists()) {
      router.push("/overview");
    }
  }, [router]);

  return (
    <>
      <Head>
        <title>Stride</title>
        <meta
          name="description"
          content="Unlock your peak performance with our cutting-edge athlete training software. Innovative features tailored for elite athletes. Designed by atheletes, for atheletes"
        />
      </Head>
      <div className="container sticky top-4 z-10 flex justify-center">
        <motion.header
          className="overflow-hidden rounded-xl bg-background"
          initial={{ width: "100%", filter: "none" }}
          animate={{
            width: smallNav ? "60%" : "100%",
            filter: smallNav
              ? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))"
              : "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.0))",
          }}
          transition={{ duration: 0.8, width: { type: "spring", bounce: 0 } }}
        >
          <div className="px-4">
            <div className="flex items-center justify-between py-2">
              <div className="flex items-center gap-5">
                <h1 className="font-stride text-2xl font-extrabold">
                  S
                  <span className={cn({ "hidden sm:inline": smallNav })}>
                    TRIDE
                  </span>
                </h1>
                <motion.div
                  className="h-5"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: smallNav ? 1 : 0 }}
                  transition={{ duration: 1, type: "spring", bounce: 0 }}
                >
                  <Separator orientation="vertical" />
                </motion.div>
                <nav className="hidden gap-0 text-sm text-foreground/60 lg:flex">
                  <Link
                    className={buttonVariants({ variant: "ghost" })}
                    href="#features"
                  >
                    Features
                  </Link>
                  {/* <Link */}
                  {/*   className={buttonVariants({ variant: "ghost" })} */}
                  {/*   href="#teams" */}
                  {/* > */}
                  {/*   Teams */}
                  {/* </Link> */}
                  <Link
                    className={buttonVariants({ variant: "ghost" })}
                    href="#pricing"
                  >
                    Pricing
                  </Link>
                </nav>
              </div>
              <motion.div
                className="flex items-center justify-center gap-2 [--translate-from:0px] [--translate-to:0px] sm:[--translate-from:10px] sm:[--translate-to:0px]"
                initial={{ translate: "var(--translate-from)" }}
                animate={{
                  translate: smallNav ? "0px" : "var(--translate-from)",
                }}
              >
                <div className="flex items-center justify-center gap-2">
                  <div
                    className={cn("flex items-center", { hidden: smallNav })}
                  >
                    <ModeToggle />
                  </div>
                  <Link
                    className={buttonVariants({ variant: "ghost" })}
                    href="/login"
                  >
                    Login
                  </Link>
                </div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: smallNav ? 1 : 0,
                  }}
                  className={smallNav ? "" : "hidden"}
                >
                  <Link
                    className={buttonVariants({ variant: "outline" })}
                    href="mailto:support@stride.is"
                  >
                    Sign Up
                  </Link>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </motion.header>
      </div>
      <div className="flex flex-col">
        <section className="space-y-6 pb-8 pt-6 md:pb-12 md:pt-10 lg:py-20">
          <div className="flex justify-center text-center">
            <div className="container flex flex-col items-center">
              <div className="mb-4 flex w-fit items-center gap-2 rounded-2xl border border-border px-4 py-1.5 text-sm font-medium">
                <p>
                  <span className="mr-2">🎉</span>Free during the open beta
                </p>
                <ArrowRightIcon />
              </div>
              <h3 className="mt-4 font-heading text-3xl font-bold text-foreground/90 sm:text-5xl md:text-6xl lg:text-7xl">
                Get More From Your <br />
                Workout Data
              </h3>
              <h3 className="pt-6 font-sans text-lg text-foreground/70">
                Get faster, fitter and stronger with Stride
              </h3>
              <div className="flex gap-4">
                {/* <div className="mt-8"> */}
                {/*   <Link */}
                {/*     className={ */}
                {/*       buttonVariants({ */}
                {/*         variant: "default", */}
                {/*         size: "lg", */}
                {/*       }) + " bg-primary/84" */}
                {/*     } */}
                {/*     href="/signup" */}
                {/*   > */}
                {/*     Get Started */}
                {/*     <ArrowRight className="ml-3 h-4 w-4" /> */}
                {/*   </Link> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </section>

        <div className="lx:px-8 container w-full justify-center lg:pt-0 xl:px-0">
          <div className="lg:rounded-3xl lg:border lg:border-border lg:p-2">
            <div className="p-4 lg:rounded-2xl lg:border lg:border-border lg:bg-muted lg:p-2">
              <Image
                alt="Screenshot of Stride's calendar"
                src={cal}
                className="w-full max-w-[1639px] overflow-hidden rounded-xl border dark:hidden lg:border-border"
              />
              <Image
                alt="Screenshot of Stride's calendar in dark mode"
                src={calDark}
                className="hidden w-full max-w-[1639px] overflow-hidden rounded-xl border border-border px-12 dark:block lg:px-0"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-wrap items-center space-y-3 px-8 pt-12 lg:px-0">
          <p className="text-muted-foreground">
            Bring your workouts and health data from anywhere
          </p>
          <div className="flex flex-row items-center gap-8">
            <div>
              <Image
                alt="Wahoo logo"
                src={wahoo}
                className="w-28 dark:invert"
              />
            </div>
            <div>
              <Image
                alt="Garmin logo"
                src={garmin}
                className="w-28 pt-2 dark:invert"
              />
            </div>
            <div>
              <Image
                alt="Whoop logo"
                src={whoop}
                className="w-28 pt-2 dark:invert"
              />
            </div>
            <div>
              <Image alt="Strava logo" src={strava} className="w-28 pt-2" />
            </div>
          </div>
        </div>

        <div className="flex justify-center pt-12">
          <div className="w-56">
            <Separator />
          </div>
        </div>

        <div className="container pt-12" id="features">
          <div className="flex flex-col items-center justify-between gap-4">
            <h3 className="text-center font-heading text-3xl">
              See your data like never before
            </h3>
            <p className="w-2/3 text-center text-muted-foreground">
              Most apps and platforms only surface a small amount of the wealth
              of data you have.
              <br /> See it all with Stride and race smarter, faster than ever
              before.
            </p>
          </div>

          <div className="gap-12 space-y-4 px-4 lg:grid lg:grid-cols-8 lg:space-y-0 lg:px-0">
            <div className="col-span-8 mt-12 flex flex-wrap overflow-hidden rounded-3xl border border-border/60 shadow-sm lg:h-96 lg:flex-nowrap">
              <div className="space-y-3 py-10 pl-10 lg:w-2/3">
                <h3 className="font-heading text-2xl">Overview</h3>
                <p className="text-muted-foreground">
                  See where your at and how you&apos;re progressing.
                </p>
                <p className="text-muted-foreground">
                  Combining data from activites and health monitoring for
                  holistic view.
                </p>

                <p className="font-semibold">
                  <Link href="/signup">Learn More</Link>
                </p>
              </div>

              <div className="hidden translate-x-20 translate-y-16 overflow-hidden rounded-xl border border-border/40 pt-1 drop-shadow-xl lg:block">
                <Image
                  alt="Overview screenshot"
                  src={overview}
                  className="block dark:hidden"
                />
                <Image
                  alt="Overview screenshot"
                  src={overviewDark}
                  className="hidden dark:block"
                />
              </div>
            </div>

            <FTPEstimation />

            <div className="flex h-72 rounded-3xl border border-border/60 shadow-sm lg:col-span-3">
              <div className="space-y-3 pl-10 pt-8">
                <h3 className="font-heading text-2xl">Sensors</h3>
                <p className="text-muted-foreground">
                  Support for new devices and sensors
                </p>
                <ul>
                  <li>Left/Right Balance</li>
                  <li>Core Body Temp</li>
                  <li>Glucose</li>
                  <li>Di2/eTap Gearing</li>
                </ul>

                <p className="font-semibold">
                  <Link href="/signup">Learn More</Link>
                </p>
              </div>
            </div>

            <div className="col-span-8 flex flex-col overflow-hidden rounded-3xl border border-border/60 shadow-sm lg:h-96 lg:flex-row">
              <div className="space-y-3 py-10 pl-10 pr-2 lg:w-1/2">
                <h3 className="font-heading text-2xl">
                  Weather <span className="ml-2">🌧️</span>
                </h3>
                <p className="text-muted-foreground">
                  Past and future weather analysis.
                </p>

                <p className="text-muted-foreground">
                  Plan the best time to train, or the best time to hit that PR.
                  <br />
                  Chose your route for headwinds and tailwinds.
                </p>

                <p className="text-muted-foreground">
                  Analyse past activities and combine with power meter data, get
                  CdA estimations.
                </p>

                <p className="font-semibold">
                  <Link href="/signup">Learn More</Link>
                </p>
              </div>

              <div className="overflow-hidden lg:w-1/2">
                <Image
                  alt="Map with weather screenshot"
                  src={map}
                  className="block h-full w-full object-cover object-center dark:hidden"
                />
                <Image
                  alt="Map with weather screenshot"
                  src={mapDark}
                  className="hidden h-full w-full object-cover object-center dark:block"
                />
              </div>
            </div>

            <div className="col-span-8 flex flex-col justify-between overflow-hidden rounded-3xl border border-border/60 shadow-sm lg:h-96 lg:flex-row">
              <div className="space-y-3 pl-10 pr-2 pt-10 lg:w-1/2">
                <h3 className="font-heading text-2xl">
                  Heart Rate Variability and Recovery
                </h3>
                <p className="text-muted-foreground">
                  HRV insights are invaluable to show how training is effecting
                  your body and recovery.
                </p>

                <p className="text-muted-foreground">
                  Perhaps more importantly, HRV can show external factors like
                  bad sleep, alcohol and illness, allowing you adapt your
                  training accordingly or make positive changes to your routive
                  and improve you capacity to train.
                </p>

                <p className="font-semibold">
                  <Link href="/signup">Learn More</Link>
                </p>
              </div>

              <div className="flex justify-center self-center overflow-hidden lg:w-1/2">
                <Image
                  alt="HRV graph"
                  src={hrv}
                  width={393}
                  className="block dark:hidden"
                />
                <Image
                  alt="Map graph"
                  src={hrvDark}
                  width={393}
                  className="hidden dark:block"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="lx:px-8 container w-full justify-center lg:mt-12 lg:pt-0 xl:px-0">
          <div className="lg:rounded-3xl lg:border lg:border-border lg:p-2">
            <div className="p-4 lg:rounded-2xl lg:border lg:border-border lg:bg-muted lg:p-2">
              <Image
                alt="Screenshot of Stride's activity view"
                src={activity}
                className="w-full max-w-[1639px] overflow-hidden rounded-xl border dark:hidden lg:border-border"
              />
              <Image
                alt="Screenshot of Stride's activity view in dark mode"
                src={activityDark}
                className="hidden w-full max-w-[1639px] overflow-hidden rounded-xl border border-border px-12 dark:block lg:px-0"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-center pt-12">
          <div className="w-56">
            <Separator />
          </div>
        </div>

        <section
          className="container flex flex-col  gap-6 px-8 py-8 md:max-w-[64rem] md:py-12 lg:py-20"
          id="pricing"
        >
          <div className="mx-auto flex w-full flex-col gap-4 md:max-w-[58rem]">
            <h2 className="font-heading text-3xl leading-[1.1] sm:text-3xl md:text-6xl">
              Simple pricing
            </h2>
            <p className="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">
              Try all features for free for 30 days!
            </p>
          </div>
          <div className="grid w-full items-center gap-10 rounded-lg border p-10 md:grid-cols-[1fr_200px]">
            <div className="grid gap-6">
              <h3 className="text-xl font-bold sm:text-2xl">
                What&apos;s included
              </h3>
              <ul className="grid gap-3 text-sm text-muted-foreground sm:grid-cols-2">
                <li className="flex items-center">
                  <Check className="mr-2 h-4 w-4" /> Overview, fatigue and load
                </li>
                <li className="flex items-center">
                  <Check className="mr-2 h-4 w-4" /> HRV and recovery
                </li>

                <li className="flex items-center">
                  <Check className="mr-2 h-4 w-4" /> Training planning
                </li>
                <li className="flex items-center">
                  <Check className="mr-2 h-4 w-4" /> Calendar
                </li>
                <li className="flex items-center">
                  <Check className="mr-2 h-4 w-4" /> All historical weather
                </li>
                <li className="flex items-center">
                  <Check className="mr-2 h-4 w-4" /> Future activity weather
                </li>
                <li className="flex items-center">
                  <Check className="mr-2 h-4 w-4" /> FTP Estimation
                </li>
                <li className="flex items-center">
                  <Check className="mr-2 h-4 w-4" /> Weight, Body Fat, from
                  devices
                </li>
              </ul>
            </div>
            <div className="">
              <div>
                <h4 className="text-7xl font-bold">£12</h4>
                <p className="text-sm font-medium text-muted-foreground">
                  Billed Monthly
                </p>
              </div>
              {/* <Link */}
              {/*   href="/signup" */}
              {/*   className={cn(buttonVariants({ size: "lg" }))} */}
              {/* > */}
              {/*   Get Started */}
              {/* </Link> */}
            </div>
          </div>
          <div className="mx-auto flex w-full max-w-[58rem] flex-col gap-4">
            <p className="max-w-[85%] leading-normal text-muted-foreground sm:leading-7">
              Stride for race teams and coaches is launching soon.{" "}
              <strong>
                Feel free to get in touch if you&apos;d like to discuss.
              </strong>
            </p>
          </div>
        </section>

        <footer className="container col-span-3 space-y-6 px-6 py-8 sm:px-0 md:py-12 lg:py-24">
          <Separator />
          <div className="grid grid-cols-2">
            <div className="space-y-2">
              <h1 className="space-x-2 font-stride text-2xl font-bold">
                STRIDE
              </h1>
              <p className="text-xs text-muted-foreground">
                71-75, Shelton Street, Covent Garden, London, WC2H 9JQ
              </p>
              <p className="text-xs text-muted-foreground">
                © 2024 STRIDE TECHNOLOGY LTD
              </p>
            </div>
            <div className="flex justify-end">
              <div className="mr-9">
                <h4 className="font-bold">About</h4>
                <ul className="text-sm text-muted-foreground">
                  <li>
                    <a href="mailto:support@stride.is">Contact</a>
                  </li>
                  <li>
                    <a href="/privacy.html">Privacy Policy</a>
                  </li>

                  <li>
                    <a href="/tos.html">Terms and Conditions</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

const FTPEstimation = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const [ready, setReady] = useState(false);

  const { reward } = useReward("reward", "confetti", {
    position: "absolute",
    lifetime: 400,
    startVelocity: 20,
    spread: 90,
    elementSize: 4,
    elementCount: 100,
  });

  return (
    <div
      className="flex h-72 rounded-3xl border border-border/60 shadow-sm lg:col-span-5"
      ref={ref}
    >
      <div className="space-y-3 pl-10 pr-2 pt-8 lg:w-2/3">
        <h3 className="font-heading text-2xl">FTP Estimation</h3>
        <p className="text-muted-foreground">
          Best in class FTP estimation using AI and data modelling
        </p>
        <p className="text-muted-foreground">
          An extremely accurate FTP based on efforts anywhere between 1 and 20
          minutes
        </p>

        <p className="font-semibold">
          <Link href="/signup">Learn More</Link>
        </p>
      </div>

      <div className="relative hidden self-end pt-10 lg:block">
        <motion.svg
          width="398"
          height="172"
          viewBox="0 0 398 172"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
        >
          <motion.path
            d="M2 170C2 170 65.1047 102.991 73.3563 102.991C81.6079 102.991 87.8696 126.586 95.5978 126.586C103.326 126.586 127.975 66.1827 135.903 66.1827C143.832 66.1827 149.935 98.8563 167.5 87.2711C185.065 75.6859 214.795 48.0284 223.543 48.0284C232.292 48.0284 239 76.2 255 62.779C271 49.3581 306.5 2.90942 322.663 2.00478C338.826 1.10015 378.109 128.801 397 126.586"
            stroke="hsl(var(--foreground))"
            strokeWidth="3"
            variants={draw}
            custom={{ delay: 0 }}
          />
        </motion.svg>
        <motion.div
          style={{
            width: "10px",
            height: "10px",
            backgroundColor: "hsl(var(--foreground))",
            position: "absolute",
            top: "40px",
            left: "0",
            offsetPath:
              "path('M 2 170 C 2 170 65.1047 102.991 73.3563 102.991 C 81.6079 102.991 87.8696 126.586 95.5978 126.586 C 103.326 126.586 127.975 66.1827 135.903 66.1827 C 143.832 66.1827 149.935 98.8563 167.5 87.2711 C 185.065 75.6859 214.795 48.0284 223.543 48.0284 C 232.292 48.0284 239 76.2 255 62.779 C 271 49.3581 306.5 2.9094 322.663 2.0048')",
          }}
          className="bg-black rounded-full"
          initial={{ offsetDistance: "0%", scale: 1, opacity: 0 }}
          animate={{
            offsetDistance: isInView ? "100%" : "0%",
            scale: 1,
            opacity: 1,
          }}
          transition={{
            duration: 2,
            yoyo: Infinity,
            ease: "easeInOut",
            delay: 1,
          }}
          onAnimationComplete={(i: { offsetDistance: string }) => {
            if (i.offsetDistance === "100%") {
              console.log("reward", i);
              setReady(true);
              reward();
            }
          }}
        />

        <motion.p
          className="absolute right-12 top-2 font-semibold opacity-0"
          initial={{ opacity: 0 }}
          animate={{
            opacity: ready ? 1 : 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
          }}
        >
          <span
            id="reward"
            style={{ width: 20, height: 20, background: "red" }}
          />
          🎉 250w
        </motion.p>
      </div>
    </div>
  );
};
